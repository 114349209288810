import React from "react";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";

export default function Contact() {
  const githubClick = () => {
    window.open("https://github.com/koltvictor", "_blank");
  };

  const emailClick = () => {
    window.open("mailto:kolt@koltdesigns.com");
  };

  const linkedInClick = () => {
    window.open("https://www.linkedin.com/in/koltadams/", "_blank");
  };
  return (
    <Container className="centered" id="contact">
      <motion.img
        className="contactImage"
        onClick={emailClick}
        src="https://i.imgur.com/n2hAMuK.jpg"
        alt="email"
        whileHover={{ scale: 1.1 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0 }}
      />
      <motion.img
        className="contactImage"
        onClick={linkedInClick}
        src="https://i.imgur.com/aNFgBpX.png"
        alt="LinkedIn"
        whileHover={{ scale: 1.1 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0 }}
      />
      <motion.img
        className="contactImage"
        onClick={githubClick}
        src="https://i.imgur.com/wFnPXx1.png"
        alt="github"
        whileHover={{ scale: 1.1 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0 }}
      />
    </Container>
  );
}
