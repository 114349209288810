// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBKUEDJqM6_NmeTRT-LOjkshAOpOqWNIZI",
  authDomain: "kolt-designs-5f8de.firebaseapp.com",
  projectId: "kolt-designs-5f8de",
  storageBucket: "kolt-designs-5f8de.appspot.com",
  messagingSenderId: "212194809098",
  appId: "1:212194809098:web:001236c5dc9bd2c93456f3",
  measurementId: "G-FTR78ZCVL5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default getFirestore(app);
