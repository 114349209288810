import { Container } from "react-bootstrap";
import React from "react";
import { onSnapshot, collection } from "firebase/firestore";
import db from "../firebase.js";
import { useEffect, useState } from "react";

export default function Project() {
  const [projects, setProjects] = useState([]);

  useEffect(
    () =>
      onSnapshot(collection(db, "projects"), (snapshot) =>
        setProjects(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  return (
    <Container style={{ paddingBottom: "5vh" }}>
      <div id="projects">
        <div className="projectTitle">HIGHLIGHTED PROJECTS</div>
        <div className="projectContainer">
          {projects.map((project) => {
            return (
              <div key={project.id}>
                <img
                  src={project.logo}
                  alt={project.name}
                  className="initial-image"
                />
                <div className="projectName">{project.name}</div>
                <div className="projectDescription">{project.description}</div>
                <div className="projectDescription">{project.languages}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
}
