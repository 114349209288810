import React from "react";
import styled from "styled-components";
import image from "../assets/kolt.png";

const AboutTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  color: #faf9f6;
  padding: 5rem;
  min-width: 100%;
`;

const BigText = styled.span`
  font-size: 3rem;
  font-weight: 900;
  margin-bottom: 1rem;
  color: #ffffff;
`;

const MediumText = styled.span`
  font-size: 2.3rem;
  font-weight: 900;
  margin-bottom: 1rem;
  color: #89cff0;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 0.8vh;
  color: #b2dfee;
`;

const Link = styled.span`
  font-weight: 900;
  color: #b2d8b2;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 25vw;
  height: 40vh;
  margin-right: 20px;
  border-radius: 30%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
`;

export default function AboutText({ githubClick, linkedInClick, emailClick }) {
  return (
    <AboutTextContainer id="about" className="background">
      <Image src={image} alt="kolt" />
      <div>
        <BigText>Hi! I'm Kolt.</BigText>
        <br />
        <MediumText>I love building things.</MediumText>
        <Paragraph>
          I'm a full-stack web and mobile developer and UI/UX enthusiast based
          in NYC and Las Vegas. I have done work in software development,
          front-end & back-end web creation, and database/server management.
        </Paragraph>
        <Paragraph>
          I am passionate about crafting engaging, accessible, and user-centric
          products and experiences for individual clients and small-businesses
          to large enterprise corporations. I focus on building and curating
          applications to the specific needs of my clients.
        </Paragraph>
        <Paragraph>
          I am always looking to expand my knowledge and experience in the field
          of technology and software development. I am currently seeking new
          opportunities to work with a team of talented individuals to create
          innovative and impactful products.
        </Paragraph>
        <Paragraph>
          When I'm not at my keyboard I'm usually rock climbing, reading,
          hanging out with my partners and three dogs, or kicking butt at Call
          of Duty.
        </Paragraph>
        <Paragraph>
          Check out some of my recent projects in my portfolio and feel free to
          take a look at my <Link onClick={githubClick}>GitHub</Link> and{" "}
          <Link onClick={linkedInClick}>LinkedIn</Link>, or send me an{" "}
          <Link onClick={emailClick}>email</Link>.
        </Paragraph>
      </div>
    </AboutTextContainer>
  );
}
