import React from "react";
import NavBar from "../components/NavBar";
import About from "../components/About";
import Contact from "../components/Contact";
import Projects from "../components/Projects";

export default function Home() {
  return (
    <div className="background">
      <NavBar />
      <About />
      <Projects />
      <Contact />
    </div>
  );
}
